import React, { useContext } from "react";
import CenteredItems from "../Utils/CenteredItems";
import Col from "react-bootstrap/Col";
import { Alert, Button, Form } from "react-bootstrap";
import { UserData } from "../../types/UserData";
import { Constants } from "../../constants/Constants";
import { InvitationProps } from "./index";
import UserDataContext from "../../context/UserDataContext";

interface InvitationFoundProps extends InvitationProps {
  updateUserData: (userData: UserData) => void;
  acceptRejectInvitation: (invitationId: string, accept: boolean) => void;
}

const InvitationFound = (props: InvitationFoundProps) => {
  const { userData } = useContext(UserDataContext);
  if (!userData) {
    return null;
  }
  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      event &&
      event.target &&
      event.target.acceptReject &&
      userData.invitationId
    ) {
      if (event.target.acceptReject.value === "accept") {
        props.acceptRejectInvitation(userData.invitationId, true);
      } else if (event.target.acceptReject.value === "reject") {
        props.acceptRejectInvitation(userData.invitationId, false);
      }
    }
  };

  return (
    <CenteredItems>
      <Col xs={false} md={3} />
      <Col xs={12} md={6}>
        <Form onSubmit={handleOnSubmit}>
          <Form.Group className={"m-5"} controlId={"alertForm"}>
            <Alert
              show={props.showAlert}
              variant={"danger"}
              dismissible
              onClose={() => props.setShowAlert(false)}
            >
              {props.alertMessage}
            </Alert>
          </Form.Group>
          <h1> Hallo {Constants.greetingName(userData)}!</h1>
          <Form.Group className={"m-5"} controlId={"acceptRejectInvitation"}>
            <p>
              Mit Deiner Einladungs-ID kannst Du uns nicht nur Rückmeldung
              geben, ob Du kommst. Du kannst hier später auch Fotos sehen.{" "}
            </p>
          </Form.Group>
        </Form>
      </Col>
    </CenteredItems>
  );
};

export default InvitationFound;
