import React from "react";
import Col from "react-bootstrap/Col";
import CenteredItems from "../Utils/CenteredItems";
import { Card, Table } from "react-bootstrap";
import Map from "../Map";
import { Constants, Places } from "../../constants/Constants";
import { renderAddress } from "../Anfahrt";

const Index = () => {
  const a3HotelAddress = Constants.GetAddressForPlaces(Places.A3Hotel);
  const westerwaldHotelAddress = Constants.GetAddressForPlaces(
    Places.WesterwaldTreff
  );
  return (
    <div className={"floralBackground fullPagePositionRelative"}>
      <CenteredItems className={"addSpaceOnTop"}>
        <Col xs={false} md={3} />
        <Col xs={12} md={4} className={"m-2"}>
          <Card className={"m-1"}>
            <Map
              latitude={a3HotelAddress.position[0]}
              longitude={a3HotelAddress.position[1]}
              address={renderAddress(Places.A3Hotel)}
            />
            <Card.Body>
              <Card.Title>
                <h2>{a3HotelAddress.name}</h2>
              </Card.Title>
              <Card.Subtitle>
                <h4>
                  In diesem Hotel haben wir ein paar Doppelzimmer und
                  Dreibettizimmer reserviert (Reservierung gültig bis:
                  22.04.2022):
                </h4>
              </Card.Subtitle>
              <Card.Text>
                <h5>
                  Bitte ruft dort an und gebt „Hochzeit Kapil“ an, um dort zu
                  buchen.
                </h5>
                <Table striped responsive className={"fs-6"}>
                  <tbody>
                    <tr>
                      <td>Preise pro Nacht, ohne Frühstück:</td>
                      <td />
                    </tr>
                    <tr>
                      <td>Doppelzimmer:</td>
                      <td>85 EURO/Nacht</td>
                    </tr>
                    <tr>
                      <td>Dreibettzimmer:</td>
                      <td>115 EURO/Nacht</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Text>
              <Card.Link href={"tel:004926345062760"} className={"fs-6"}>
                Telefon: +49 26 34 / 506 27 60
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4} className={"m-2"}>
          <Card className={"m-1"}>
            <Map
              latitude={westerwaldHotelAddress.position[0]}
              longitude={westerwaldHotelAddress.position[1]}
              address={renderAddress(Places.WesterwaldTreff)}
            />
            <Card.Body>
              <Card.Title>
                <h2>{westerwaldHotelAddress.name}</h2>
              </Card.Title>
              <Card.Subtitle>
                <h4></h4>
              </Card.Subtitle>
              <Card.Text>
                <h5></h5>
                <Table striped responsive className={"fs-6"}>
                  <tbody>
                    <tr>
                      <td>Preise pro Nacht, ohne Frühstück:</td>
                      <td>Einzelzimmer</td>
                      <td>Doppelzimmer</td>
                    </tr>
                    <tr>
                      <td>Standardzimmer:</td>
                      <td>80 EURO/Nacht/Person</td>
                      <td>60 EURO/Nacht/Person</td>
                    </tr>
                    <tr>
                      <td>Komfortzimmer:</td>
                      <td>90 EURO/Nacht/Person</td>
                      <td>70 EURO/Nacht/Person</td>
                    </tr>
                    <tr>
                      <td>Premiumzimmer:</td>
                      <td>92,50 EURO/Nacht/Person</td>
                      <td>72,50 EURO/Nacht/Person</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Text>
              <Card.Link href={"tel:00492685870"} className={"fs-6"}>
                Telefon: +49 26 85 / 87 0
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
      </CenteredItems>
    </div>
  );
};

export default Index;
