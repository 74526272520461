import React, { useState } from "react";
import CenteredItems from "../Utils/CenteredItems";
import Col from "react-bootstrap/Col";
import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import photos from "./data";

const Photos = () => {
  const [clickedPictureIndex, setClickedPictureIndex] = useState(-1);
  console.log("Photos, Line 18 photos: ", photos);
  return (
    <div className={"floralBackground fullPagePositionRelative"}>
      {/*<CenteredItems className={"addSpaceOnTop"}></CenteredItems>*/}
      {/*<Col xs={false} md={3} />*/}
      {/*<Col xs={12} md={4} className={"m-2"}>*/}

      <PhotoAlbum
        photos={photos}
        layout={"masonry"}
        padding={0}
        spacing={2}
        onClick={(event, photo, index) => setClickedPictureIndex(index)}
      />
      <Lightbox
        slides={photos}
        open={clickedPictureIndex >= 0}
        index={clickedPictureIndex}
        close={() => setClickedPictureIndex(-1)}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
      {/*</Col>*/}
    </div>
  );
};

export default Photos;
