import React from "react";
import { AlternateCenteredItems } from "../Utils/CenteredItems";
import Col from "react-bootstrap/Col";

const InfoItem = () => {
  return (
    <div className={"floralBackground fullPage"}>
      <AlternateCenteredItems className={"text-center"}>
        <Col>
          <h1 className={"windSong"}>Es war wunderschön!</h1>
          <p>Als unsere Gäste habt ihr sehr dazu beigetragen,</p>
          <p>dass unsere Hochzeit ein besonders schönes Fest wurde.</p>
          <p></p>
          <p>
            Dafür sowie für alle Glückwünsche und Geschenke sagen wir herzlich
            DANKE!
          </p>
        </Col>
      </AlternateCenteredItems>
    </div>
  );
};

export default InfoItem;
