import React from "react";
import {UserData} from "../types/UserData";

interface UserDataContextType {
    userData?: UserData | undefined;
    updateUserData: (userData: UserData) => void;
}

class UserDataContextTypeClass implements UserDataContextType {
    updateUserData(userData: UserData): void {
    }

    userData: UserData | undefined;

}

const UserDataContext = React.createContext(new UserDataContextTypeClass());

export default UserDataContext;
