import React, { useContext, useState } from "react";
import { DefaultReactComponentProps } from "../../types/DefaultReactComponentProps";
import { useLocation } from "react-router-dom";
import { Constants } from "../../constants/Constants";
import EnterInvitation from "./EnterInvitation";
import InvitationFound from "./InvitationFound";
import InvitedRejected from "./InvitedRejected";
import InvitationAccepted from "./InvitationAccepted";
import axios from "axios";
import { UserData } from "../../types/UserData";
import InvitationFromUrl from "./InvitationFromURL";
import UserDataContext from "../../context/UserDataContext";

export interface InvitationProps extends DefaultReactComponentProps {
  handleInvitationEntered?: (invitationId: string) => void;
  userData?: UserData;
  showAlert: boolean | undefined;
  setShowAlert: (value: boolean) => void;
  alertMessage: string | undefined;
  updateUserData?: (userData: UserData) => void;
  acceptRejectInvitation?: (invitationId: string, accept: boolean) => void;
}

interface InvitationComponentProps extends DefaultReactComponentProps {}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Invitation = (props: InvitationComponentProps) => {
  const query = useQuery();
  const { userData, updateUserData } = useContext(UserDataContext);
  const findInviteId = () => {
    const fromQueryParam = query.get(Constants.QueryParams.invitationId);
    if (fromQueryParam === undefined || fromQueryParam == null) {
      const inLocalStorage = Constants.findInvitationIdFromLocalStorage();
      if (inLocalStorage === undefined || inLocalStorage === null) {
        return undefined;
      } else {
        return inLocalStorage.toUpperCase();
      }
    } else {
      return fromQueryParam.toUpperCase();
    }
  };

  const updateInvitationId = (invitationId: string) => {
    if (invitationId !== undefined) {
      const invitationIsUpperCase = invitationId.toUpperCase();
      localStorage.setItem(
        Constants.LocalStorage.invitationId,
        invitationIsUpperCase
      );
      setInvitationId(invitationId);
    }
  };
  const updateAlertData = (show: boolean, message: string) => {
    setAlertMessage(message);
    setShowAlert(show);
  };
  const shouldShowInvitationFromUrlPage = () => {
    if (
      invitationId &&
      (!userData || (userData && userData.invitationId !== invitationId))
    ) {
      return true;
    }
    return "";
  };
  // set states
  const [invitationId, setInvitationId] = useState(findInviteId());
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleInvitationCodeEntered = (invitationId: string) => {
    if (invitationId) {
      axios
        .get(`/hochzeit/api/invitation/${invitationId}/`)
        .then((result) => {
          if (result.data) {
            const user = result.data;
            updateInvitationId(invitationId);
            updateUserData(user);
          } else {
            updateAlertData(
              true,
              "Unbekannte Einladung Id. Bitte versuchen Sie erneut!"
            );
          }
        })
        .catch((error) => {
          updateAlertData(true, "Unbekannte Fehler! Bitte melden Sie bie uns.");
        });
    }
  };
  const acceptRejectInvitation = (invitationId: string, accept: boolean) => {
    axios
      .post(`/hochzeit/api/invitation/${invitationId}/?accept=${accept}`)
      .then((result) => {
        if (result.data) {
          const user = result.data;
          updateUserData(user);
        } else {
          updateAlertData(
            true,
            "Unbekannte Einladung Id. Bitte versuchen Sie erneut!"
          );
        }
      })
      .catch((error) => {
        updateAlertData(true, "Unbekannte Fehler! Bitte melden Sie bie uns.");
      });
  };

  return (
    <div className={"floralBackground fullPagePositionRelative m-2"}>
      {!invitationId && (
        <EnterInvitation
          handleInvitationEntered={handleInvitationCodeEntered}
          showAlert={showAlert}
          alertMessage={alertMessage}
          setShowAlert={setShowAlert}
        />
      )}
      {shouldShowInvitationFromUrlPage() && invitationId && (
        <InvitationFromUrl
          invitationId={invitationId}
          handleInvitationEntered={handleInvitationCodeEntered}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          alertMessage={alertMessage}
        />
      )}

      {invitationId && userData && userData.accepted === undefined && (
        <InvitationFound
          showAlert={showAlert}
          alertMessage={alertMessage}
          setShowAlert={setShowAlert}
          updateUserData={updateUserData}
          acceptRejectInvitation={acceptRejectInvitation}
        />
      )}
      {userData && userData.accepted !== undefined && !userData.accepted && (
        <InvitedRejected
          showAlert={showAlert}
          alertMessage={alertMessage}
          setShowAlert={setShowAlert}
          acceptRejectInvitation={acceptRejectInvitation}
        />
      )}
      {userData && userData.accepted !== undefined && userData.accepted && (
        <InvitationAccepted
          userData={userData}
          showAlert={showAlert}
          alertMessage={alertMessage}
          setShowAlert={setShowAlert}
          updateUserData={updateUserData}
          updateAlertData={updateAlertData}
          acceptRejectInvitation={acceptRejectInvitation}
        />
      )}
    </div>
  );
};
export default Invitation;
