import React from "react";
import CenteredItems from "../Utils/CenteredItems";
import Col from "react-bootstrap/Col";
import { Card, Table } from "react-bootstrap";
import Map from "../Map";
import { Constants, Places } from "../../constants/Constants";
import { renderAddress } from "../Anfahrt";

const Index = () => {
  const eltern = Constants.GetAddressForPlaces(Places.Eltern);
  return (
    <div className={"floralBackground fullPagePositionRelative"}>
      <CenteredItems>
        <Col xs={false} md={3} />
        <Col xs={12} md={6} className={"m-2"}>
          <h2>Wir poltern!</h2>
          <p>
            Gepoltert wird bereits am Donnerstag, 07.07.2022 bei Marias Eltern
            zu Hause.
          </p>
          <h4>Scherben bringen Glück 😃</h4>
          <Card>
            <Map
              latitude={eltern.position[0]
              }
              longitude={eltern.position[1]
              }
              address={renderAddress(Places.Eltern)}
            />
            <Card.Body>
              <Card.Text>
                <Table responsive striped className={'fs-6'}>
                  <tbody>
                    <tr>
                      <td>{eltern.info}</td>
                      <td>{eltern.name}</td>
                      <td>{eltern.address}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </CenteredItems>
    </div>
  );
};

export default Index;
