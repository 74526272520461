import Image_00001 from "../../assets/images/hochzeit/Image_00001.jpeg";
// @ts-ignore
import Image_00002 from "../../assets/images/hochzeit/Image_00002.jpeg";
// @ts-ignore
import Image_00003 from "../../assets/images/hochzeit/Image_00003.jpeg";
// @ts-ignore
import Image_00004 from "../../assets/images/hochzeit/Image_00004.jpeg";
// @ts-ignore
import Image_00005 from "../../assets/images/hochzeit/Image_00005.jpeg";
// @ts-ignore
import Image_00006 from "../../assets/images/hochzeit/Image_00006.jpeg";
// @ts-ignore
import Image_00007 from "../../assets/images/hochzeit/Image_00007.jpeg";
// @ts-ignore
import Image_00008 from "../../assets/images/hochzeit/Image_00008.jpeg";
// @ts-ignore
import Image_00009 from "../../assets/images/hochzeit/Image_00009.jpeg";
// @ts-ignore
import Image_00010 from "../../assets/images/hochzeit/Image_00010.jpeg";
// @ts-ignore
import Image_00011 from "../../assets/images/hochzeit/Image_00011.jpeg";
// @ts-ignore
import Image_00012 from "../../assets/images/hochzeit/Image_00012.jpeg";
// @ts-ignore
import Image_00013 from "../../assets/images/hochzeit/Image_00013.jpeg";
// @ts-ignore
import Image_00014 from "../../assets/images/hochzeit/Image_00014.jpeg";
// @ts-ignore
import Image_00015 from "../../assets/images/hochzeit/Image_00015.jpeg";
// @ts-ignore
import Image_00016 from "../../assets/images/hochzeit/Image_00016.jpeg";
// @ts-ignore
import Image_00017 from "../../assets/images/hochzeit/Image_00017.jpeg";
// @ts-ignore
import Image_00018 from "../../assets/images/hochzeit/Image_00018.jpeg";
// @ts-ignore
import Image_00019 from "../../assets/images/hochzeit/Image_00019.jpeg";
// @ts-ignore
import Image_00020 from "../../assets/images/hochzeit/Image_00020.jpeg";
// @ts-ignore
import Image_00021 from "../../assets/images/hochzeit/Image_00021.jpeg";
// @ts-ignore
import Image_00022 from "../../assets/images/hochzeit/Image_00022.jpeg";
// @ts-ignore
import Image_00023 from "../../assets/images/hochzeit/Image_00023.jpeg";
// @ts-ignore
import Image_00024 from "../../assets/images/hochzeit/Image_00024.jpeg";
// @ts-ignore
import Image_00025 from "../../assets/images/hochzeit/Image_00025.jpeg";
// @ts-ignore
import Image_00026 from "../../assets/images/hochzeit/Image_00026.jpeg";
// @ts-ignore
import Image_00027 from "../../assets/images/hochzeit/Image_00027.jpeg";
// @ts-ignore
import Image_00028 from "../../assets/images/hochzeit/Image_00028.jpeg";
// @ts-ignore
import Image_00029 from "../../assets/images/hochzeit/Image_00029.JPG";
// @ts-ignore
import Image_00030 from "../../assets/images/hochzeit/Image_00030.jpeg";
// @ts-ignore
import Image_00031 from "../../assets/images/hochzeit/Image_00031.JPG";
// @ts-ignore
import Image_00032 from "../../assets/images/hochzeit/Image_00032.jpeg";
// @ts-ignore
import Image_00033 from "../../assets/images/hochzeit/Image_00033.JPG";
// @ts-ignore
import Image_00034 from "../../assets/images/hochzeit/Image_00034.JPG";
// @ts-ignore
import Image_00035 from "../../assets/images/hochzeit/Image_00035.JPG";
// @ts-ignore
import Image_00036 from "../../assets/images/hochzeit/Image_00036.JPG";
// @ts-ignore
import Image_00037 from "../../assets/images/hochzeit/Image_00037.JPG";
// @ts-ignore
import Image_00038 from "../../assets/images/hochzeit/Image_00038.JPG";
// @ts-ignore
import Image_00039 from "../../assets/images/hochzeit/Image_00039.JPG";
// @ts-ignore
import Image_00040 from "../../assets/images/hochzeit/Image_00040.JPG";
// @ts-ignore
import Image_00041 from "../../assets/images/hochzeit/Image_00041.JPG";
// @ts-ignore
import Image_00042 from "../../assets/images/hochzeit/Image_00042.JPG";
// @ts-ignore
import Image_00043 from "../../assets/images/hochzeit/Image_00043.JPG";
// @ts-ignore
import Image_00044 from "../../assets/images/hochzeit/Image_00044.JPG";
// @ts-ignore
import Image_00045 from "../../assets/images/hochzeit/Image_00045.JPG";
// @ts-ignore
import Image_00046 from "../../assets/images/hochzeit/Image_00046.JPG";
// @ts-ignore
import Image_00047 from "../../assets/images/hochzeit/Image_00047.JPG";
// @ts-ignore
import Image_00048 from "../../assets/images/hochzeit/Image_00048.JPG";
// @ts-ignore
import Image_00049 from "../../assets/images/hochzeit/Image_00049.jpeg";
// @ts-ignore
import Image_00050 from "../../assets/images/hochzeit/Image_00050.JPG";
// @ts-ignore
import Image_00051 from "../../assets/images/hochzeit/Image_00051.JPG";
// @ts-ignore
import Image_00052 from "../../assets/images/hochzeit/Image_00052.JPG";
// @ts-ignore
import Image_00053 from "../../assets/images/hochzeit/Image_00053.JPG";
// @ts-ignore
import Image_00054 from "../../assets/images/hochzeit/Image_00054.JPG";
// @ts-ignore
import Image_00055 from "../../assets/images/hochzeit/Image_00055.JPG";
// @ts-ignore
import Image_00056 from "../../assets/images/hochzeit/Image_00056.JPG";
// @ts-ignore
import Image_00057 from "../../assets/images/hochzeit/Image_00057.JPG";
// @ts-ignore
import Image_00058 from "../../assets/images/hochzeit/Image_00058.JPG";
// @ts-ignore
import Image_00059 from "../../assets/images/hochzeit/Image_00059.JPG";
// @ts-ignore
import Image_00060 from "../../assets/images/hochzeit/Image_00060.JPG";
// @ts-ignore
import Image_00061 from "../../assets/images/hochzeit/Image_00061.JPG";
// @ts-ignore
import Image_00062 from "../../assets/images/hochzeit/Image_00062.JPG";
// @ts-ignore
import Image_00063 from "../../assets/images/hochzeit/Image_00063.JPG";
// @ts-ignore
import Image_00064 from "../../assets/images/hochzeit/Image_00064.JPG";
// @ts-ignore
import Image_00065 from "../../assets/images/hochzeit/Image_00065.JPG";
// @ts-ignore
import Image_00066 from "../../assets/images/hochzeit/Image_00066.JPG";
// @ts-ignore
import Image_00067 from "../../assets/images/hochzeit/Image_00067.JPG";
// @ts-ignore
import Image_00068 from "../../assets/images/hochzeit/Image_00068.JPG";
// @ts-ignore
import Image_00069 from "../../assets/images/hochzeit/Image_00069.JPG";
// @ts-ignore
import Image_00070 from "../../assets/images/hochzeit/Image_00070.JPG";
// @ts-ignore
import Image_00071 from "../../assets/images/hochzeit/Image_00071.JPG";
// @ts-ignore
import Image_00072 from "../../assets/images/hochzeit/Image_00072.JPG";
// @ts-ignore
import Image_00073 from "../../assets/images/hochzeit/Image_00073.JPG";
// @ts-ignore
import Image_00074 from "../../assets/images/hochzeit/Image_00074.JPG";
// @ts-ignore
import Image_00075 from "../../assets/images/hochzeit/Image_00075.JPG";
// @ts-ignore
import Image_00076 from "../../assets/images/hochzeit/Image_00076.JPG";
// @ts-ignore
import Image_00077 from "../../assets/images/hochzeit/Image_00077.JPG";
// @ts-ignore
import Image_00078 from "../../assets/images/hochzeit/Image_00078.JPG";
// @ts-ignore
import Image_00079 from "../../assets/images/hochzeit/Image_00079.JPG";
// @ts-ignore
import Image_00080 from "../../assets/images/hochzeit/Image_00080.JPG";
// @ts-ignore
import Image_00081 from "../../assets/images/hochzeit/Image_00081.JPG";
// @ts-ignore
import Image_00082 from "../../assets/images/hochzeit/Image_00082.JPG";
// @ts-ignore
import Image_00083 from "../../assets/images/hochzeit/Image_00083.JPG";
// @ts-ignore
import Image_00084 from "../../assets/images/hochzeit/Image_00084.JPG";
// @ts-ignore
import Image_00085 from "../../assets/images/hochzeit/Image_00085.JPG";
// @ts-ignore
import Image_00087 from "../../assets/images/hochzeit/Image_00087.JPG";
// @ts-ignore
import Image_00089 from "../../assets/images/hochzeit/Image_00089.JPG";
// @ts-ignore
import Image_00090 from "../../assets/images/hochzeit/Image_00090.JPG";
// @ts-ignore
import Image_00091 from "../../assets/images/hochzeit/Image_00091.JPG";
// @ts-ignore
import Image_00092 from "../../assets/images/hochzeit/Image_00092.JPG";
// @ts-ignore
import Image_00093 from "../../assets/images/hochzeit/Image_00093.JPG";
// @ts-ignore
import Image_00094 from "../../assets/images/hochzeit/Image_00094.JPG";
// @ts-ignore
import Image_00095 from "../../assets/images/hochzeit/Image_00095.JPG";
// @ts-ignore
import Image_00096 from "../../assets/images/hochzeit/Image_00096.JPG";
// @ts-ignore
import Image_00097 from "../../assets/images/hochzeit/Image_00097.JPG";
// @ts-ignore
import Image_00098 from "../../assets/images/hochzeit/Image_00098.jpeg";
// @ts-ignore
import Image_00099 from "../../assets/images/hochzeit/Image_00099.jpeg";
// @ts-ignore
import Image_00100 from "../../assets/images/hochzeit/Image_00100.JPG";
// @ts-ignore
import Image_00101 from "../../assets/images/hochzeit/Image_00101.jpeg";
// @ts-ignore
import Image_00102 from "../../assets/images/hochzeit/Image_00102.jpeg";
// @ts-ignore
import Image_00103 from "../../assets/images/hochzeit/Image_00103.JPG";
// @ts-ignore
import Image_00104 from "../../assets/images/hochzeit/Image_00104.jpeg";
// @ts-ignore
import Image_00105 from "../../assets/images/hochzeit/Image_00105.jpeg";
// @ts-ignore
import Image_00106 from "../../assets/images/hochzeit/Image_00106.JPG";
// @ts-ignore
import Image_00107 from "../../assets/images/hochzeit/Image_00107.JPG";
// @ts-ignore
import Image_00108 from "../../assets/images/hochzeit/Image_00108.JPG";
// @ts-ignore
import Image_00109 from "../../assets/images/hochzeit/Image_00109.JPG";
// @ts-ignore
import Image_00110 from "../../assets/images/hochzeit/Image_00110.JPG";
// @ts-ignore
import Image_00111 from "../../assets/images/hochzeit/Image_00111.JPG";
// @ts-ignore
import Image_00112 from "../../assets/images/hochzeit/Image_00112.JPG";
// @ts-ignore
import Image_00113 from "../../assets/images/hochzeit/Image_00113.JPG";
// @ts-ignore
import Image_00114 from "../../assets/images/hochzeit/Image_00114.JPG";
// @ts-ignore
import Image_00115 from "../../assets/images/hochzeit/Image_00115.JPG";
// @ts-ignore
import Image_00116 from "../../assets/images/hochzeit/Image_00116.JPG";
// @ts-ignore
import Image_00117 from "../../assets/images/hochzeit/Image_00117.JPG";
// @ts-ignore
import Image_00118 from "../../assets/images/hochzeit/Image_00118.JPG";
// @ts-ignore
import Image_00119 from "../../assets/images/hochzeit/Image_00119.JPG";
// @ts-ignore
import Image_00120 from "../../assets/images/hochzeit/Image_00120.JPG";
// @ts-ignore
import Image_00121 from "../../assets/images/hochzeit/Image_00121.JPG";
// @ts-ignore
import Image_00122 from "../../assets/images/hochzeit/Image_00122.JPG";
// @ts-ignore
import Image_00123 from "../../assets/images/hochzeit/Image_00123.JPG";
// @ts-ignore
import Image_00124 from "../../assets/images/hochzeit/Image_00124.jpeg";
// @ts-ignore
import Image_00125 from "../../assets/images/hochzeit/Image_00125.jpeg";
// @ts-ignore
import Image_00126 from "../../assets/images/hochzeit/Image_00126.jpeg";
// @ts-ignore
import Image_00127 from "../../assets/images/hochzeit/Image_00127.jpeg";
// @ts-ignore
import Image_00128 from "../../assets/images/hochzeit/Image_00128.jpeg";
// @ts-ignore
import Image_00129 from "../../assets/images/hochzeit/Image_00129.jpeg";
// @ts-ignore
import Image_00130 from "../../assets/images/hochzeit/Image_00130.jpeg";
// @ts-ignore
import Image_00131 from "../../assets/images/hochzeit/Image_00131.jpeg";
// @ts-ignore
import Image_00132 from "../../assets/images/hochzeit/Image_00132.jpeg";
// @ts-ignore
import Image_00133 from "../../assets/images/hochzeit/Image_00133.jpeg";
// @ts-ignore
import Image_00134 from "../../assets/images/hochzeit/Image_00134.jpeg";
// @ts-ignore
import Image_00135 from "../../assets/images/hochzeit/Image_00135.jpeg";
// @ts-ignore
import Image_00136 from "../../assets/images/hochzeit/Image_00136.jpeg";
// @ts-ignore
import Image_00137 from "../../assets/images/hochzeit/Image_00137.jpeg";
// @ts-ignore
import Image_00138 from "../../assets/images/hochzeit/Image_00138.jpeg";
// @ts-ignore
import Image_00139 from "../../assets/images/hochzeit/Image_00139.jpeg";
// @ts-ignore
import Image_00140 from "../../assets/images/hochzeit/Image_00140.jpeg";
// @ts-ignore
import Image_00141 from "../../assets/images/hochzeit/Image_00141.jpeg";
// @ts-ignore
import Image_00142 from "../../assets/images/hochzeit/Image_00142.jpeg";
// @ts-ignore
import Image_00143 from "../../assets/images/hochzeit/Image_00143.jpeg";
// @ts-ignore
import Image_00144 from "../../assets/images/hochzeit/Image_00144.jpeg";
// @ts-ignore
import Image_00145 from "../../assets/images/hochzeit/Image_00145.jpeg";
// @ts-ignore
import Image_00146 from "../../assets/images/hochzeit/Image_00146.jpeg";
// @ts-ignore
import Image_00147 from "../../assets/images/hochzeit/Image_00147.jpeg";
// @ts-ignore
import Image_00148 from "../../assets/images/hochzeit/Image_00148.jpeg";
// @ts-ignore
import Image_00149 from "../../assets/images/hochzeit/Image_00149.jpeg";
// @ts-ignore
import Image_00150 from "../../assets/images/hochzeit/Image_00150.jpeg";
// @ts-ignore
import Image_00151 from "../../assets/images/hochzeit/Image_00151.jpeg";
// @ts-ignore
import Image_00152 from "../../assets/images/hochzeit/Image_00152.jpeg";
// @ts-ignore
import Image_00153 from "../../assets/images/hochzeit/Image_00153.jpeg";
// @ts-ignore
import Image_00154 from "../../assets/images/hochzeit/Image_00154.jpeg";
// @ts-ignore
import Image_00155 from "../../assets/images/hochzeit/Image_00155.jpeg";
// @ts-ignore
import Image_00156 from "../../assets/images/hochzeit/Image_00156.jpeg";
// @ts-ignore
import Image_00157 from "../../assets/images/hochzeit/Image_00157.jpeg";
// @ts-ignore
import Image_00158 from "../../assets/images/hochzeit/Image_00158.jpeg";
// @ts-ignore
import Image_00159 from "../../assets/images/hochzeit/Image_00159.jpeg";
// @ts-ignore
import Image_00160 from "../../assets/images/hochzeit/Image_00160.jpeg";
// @ts-ignore
import Image_00161 from "../../assets/images/hochzeit/Image_00161.jpeg";
// @ts-ignore
import Image_00162 from "../../assets/images/hochzeit/Image_00162.jpeg";
// @ts-ignore
import Image_00163 from "../../assets/images/hochzeit/Image_00163.jpeg";
// @ts-ignore
import Image_00164 from "../../assets/images/hochzeit/Image_00164.jpeg";
// @ts-ignore
import Image_00165 from "../../assets/images/hochzeit/Image_00165.jpeg";
// @ts-ignore
import Image_00166 from "../../assets/images/hochzeit/Image_00166.jpeg";
// @ts-ignore
import Image_00167 from "../../assets/images/hochzeit/Image_00167.jpeg";
// @ts-ignore
import Image_00168 from "../../assets/images/hochzeit/Image_00168.jpeg";
// @ts-ignore
import Image_00169 from "../../assets/images/hochzeit/Image_00169.jpeg";
// @ts-ignore
import Image_00170 from "../../assets/images/hochzeit/Image_00170.jpeg";
// @ts-ignore
import Image_00171 from "../../assets/images/hochzeit/Image_00171.jpeg";
// @ts-ignore
import Image_00172 from "../../assets/images/hochzeit/Image_00172.jpeg";
// @ts-ignore
import Video_00001 from "../../assets/images/hochzeit/Video_00001.mov";

import { Photo } from "react-photo-album";

const weddingPhotos = [
  { src: Image_00001, width: 1537, height: 2305 },
  { src: Image_00002, width: 1537, height: 2305 },
  { src: Image_00003, width: 1200, height: 600 },
  { src: Image_00004, width: 1536, height: 2049 },
  { src: Image_00005, width: 1537, height: 2305 },
  { src: Image_00006, width: 1536, height: 2049 },
  { src: Image_00007, width: 1537, height: 2305 },
  { src: Image_00008, width: 1537, height: 2305 },
  { src: Image_00009, width: 1537, height: 2305 },
  { src: Image_00010, width: 1537, height: 2305 },
  { src: Image_00011, width: 1537, height: 2305 },
  { src: Image_00012, width: 1537, height: 2305 },
  { src: Image_00013, width: 1537, height: 2305 },
  { src: Image_00014, width: 1537, height: 2305 },
  { src: Image_00015, width: 1537, height: 2305 },
  { src: Image_00016, width: 2305, height: 1537 },
  { src: Image_00017, width: 2305, height: 1537 },
  { src: Image_00018, width: 1537, height: 2305 },
  { src: Image_00019, width: 1537, height: 2305 },
  { src: Image_00020, width: 1537, height: 2305 },
  { src: Image_00021, width: 1537, height: 2305 },
  { src: Image_00022, width: 1600, height: 1200 },
  { src: Image_00023, width: 1537, height: 2305 },
  { src: Image_00024, width: 1537, height: 2305 },
  { src: Image_00025, width: 2305, height: 1537 },
  { src: Image_00026, width: 2305, height: 1537 },
  { src: Image_00027, width: 1536, height: 2049 },
  { src: Image_00028, width: 2048, height: 2048 },
  { src: Image_00029, width: 1260, height: 840 },
  { src: Image_00030, width: 2048, height: 2048 },
  { src: Image_00031, width: 1260, height: 840 },
  { src: Image_00032, width: 1488, height: 1984 },
  { src: Image_00033, width: 1260, height: 840 },
  { src: Image_00034, width: 1260, height: 840 },
  { src: Image_00035, width: 1260, height: 840 },
  { src: Image_00036, width: 1260, height: 840 },
  { src: Image_00037, width: 1260, height: 840 },
  { src: Image_00038, width: 1260, height: 840 },
  { src: Image_00039, width: 1260, height: 840 },
  { src: Image_00040, width: 1260, height: 840 },
  { src: Image_00041, width: 1260, height: 840 },
  { src: Image_00042, width: 1260, height: 840 },
  { src: Image_00043, width: 1260, height: 840 },
  { src: Image_00044, width: 1260, height: 840 },
  { src: Image_00045, width: 1260, height: 840 },
  { src: Image_00046, width: 1260, height: 840 },
  { src: Image_00047, width: 1260, height: 840 },
  { src: Image_00048, width: 1260, height: 840 },
  { src: Image_00049, width: 1537, height: 2305 },
  { src: Image_00050, width: 1260, height: 840 },
  { src: Image_00051, width: 1260, height: 840 },
  { src: Image_00052, width: 1260, height: 840 },
  { src: Image_00053, width: 1260, height: 840 },
  { src: Image_00054, width: 1260, height: 840 },
  { src: Image_00055, width: 1260, height: 840 },
  { src: Image_00056, width: 1260, height: 840 },
  { src: Image_00057, width: 1260, height: 840 },
  { src: Image_00058, width: 1260, height: 840 },
  { src: Image_00059, width: 1260, height: 840 },
  { src: Image_00060, width: 1260, height: 840 },
  { src: Image_00061, width: 1260, height: 840 },
  { src: Image_00062, width: 1260, height: 840 },
  { src: Image_00063, width: 1260, height: 840 },
  { src: Image_00064, width: 840, height: 1260 },
  { src: Image_00065, width: 1260, height: 840 },
  { src: Image_00066, width: 1260, height: 840 },
  { src: Image_00067, width: 1260, height: 840 },
  { src: Image_00068, width: 1260, height: 840 },
  { src: Image_00069, width: 1260, height: 840 },
  { src: Image_00070, width: 1260, height: 840 },
  { src: Image_00071, width: 1260, height: 840 },
  { src: Image_00072, width: 1260, height: 840 },
  { src: Image_00073, width: 840, height: 1260 },
  { src: Image_00074, width: 1260, height: 840 },
  { src: Image_00075, width: 1260, height: 840 },
  { src: Image_00076, width: 1260, height: 840 },
  { src: Image_00077, width: 1260, height: 840 },
  { src: Image_00078, width: 1260, height: 840 },
  { src: Image_00079, width: 1260, height: 840 },
  { src: Image_00080, width: 1260, height: 840 },
  { src: Image_00081, width: 1260, height: 840 },
  { src: Image_00082, width: 1260, height: 840 },
  { src: Image_00083, width: 1260, height: 840 },
  { src: Image_00084, width: 1260, height: 840 },
  { src: Image_00085, width: 1260, height: 840 },
  { src: Image_00087, width: 1260, height: 840 },
  { src: Image_00089, width: 1260, height: 840 },
  { src: Image_00090, width: 1260, height: 840 },
  { src: Image_00091, width: 1260, height: 840 },
  { src: Image_00092, width: 1260, height: 840 },
  { src: Image_00093, width: 1260, height: 840 },
  { src: Image_00094, width: 1260, height: 840 },
  { src: Image_00095, width: 1260, height: 840 },
  { src: Image_00096, width: 1260, height: 840 },
  { src: Image_00097, width: 1260, height: 840 },
  { src: Image_00098, width: 1537, height: 2305 },
  { src: Image_00099, width: 1537, height: 2305 },
  { src: Image_00100, width: 1260, height: 840 },
  { src: Image_00101, width: 1537, height: 2305 },
  { src: Image_00102, width: 1537, height: 2305 },
  { src: Image_00103, width: 1260, height: 840 },
  { src: Image_00104, width: 1537, height: 2305 },
  { src: Image_00105, width: 1537, height: 2305 },
  { src: Image_00106, width: 1260, height: 840 },
  { src: Image_00107, width: 1260, height: 840 },
  { src: Image_00108, width: 1260, height: 840 },
  { src: Image_00109, width: 1260, height: 840 },
  { src: Image_00110, width: 1260, height: 840 },
  { src: Image_00111, width: 1260, height: 840 },
  { src: Image_00112, width: 1260, height: 840 },
  { src: Image_00113, width: 1260, height: 840 },
  { src: Image_00114, width: 1260, height: 840 },
  { src: Image_00115, width: 1260, height: 840 },
  { src: Image_00116, width: 1260, height: 840 },
  { src: Image_00117, width: 1260, height: 840 },
  { src: Image_00118, width: 1260, height: 840 },
  { src: Image_00119, width: 1260, height: 840 },
  { src: Image_00120, width: 1260, height: 840 },
  { src: Image_00121, width: 1260, height: 840 },
  { src: Image_00122, width: 1260, height: 840 },
  { src: Image_00123, width: 1260, height: 840 },
  { src: Image_00124, width: 1537, height: 2305 },
  { src: Image_00125, width: 1537, height: 2305 },
  { src: Image_00126, width: 1537, height: 2305 },
  { src: Image_00127, width: 2305, height: 1537 },
  { src: Image_00128, width: 1537, height: 2305 },
  { src: Image_00129, width: 1537, height: 2305 },
  { src: Image_00130, width: 2305, height: 1537 },
  { src: Image_00131, width: 2305, height: 1537 },
  { src: Image_00132, width: 1537, height: 2305 },
  { src: Image_00133, width: 2305, height: 1537 },
  { src: Image_00134, width: 1537, height: 2305 },
  { src: Image_00135, width: 2305, height: 1537 },
  { src: Image_00136, width: 1537, height: 2305 },
  { src: Image_00137, width: 1537, height: 2305 },
  { src: Image_00138, width: 1537, height: 2305 },
  { src: Image_00139, width: 1537, height: 2305 },
  { src: Image_00140, width: 2305, height: 1537 },
  { src: Image_00141, width: 1537, height: 2305 },
  { src: Image_00142, width: 1537, height: 2305 },
  { src: Image_00143, width: 1537, height: 2305 },
  { src: Image_00144, width: 1537, height: 2305 },
  { src: Image_00145, width: 1537, height: 2305 },
  { src: Image_00146, width: 1537, height: 2305 },
  { src: Image_00147, width: 2305, height: 1537 },
  { src: Image_00148, width: 1537, height: 2305 },
  { src: Image_00149, width: 2305, height: 1537 },
  { src: Image_00150, width: 2305, height: 1537 },
  { src: Image_00151, width: 2305, height: 1537 },
  { src: Image_00152, width: 1537, height: 2305 },
  { src: Image_00153, width: 738, height: 1600 },
  { src: Image_00154, width: 1537, height: 2305 },
  { src: Image_00155, width: 1537, height: 2305 },
  { src: Image_00156, width: 1537, height: 2305 },
  { src: Image_00157, width: 1537, height: 2305 },
  { src: Image_00158, width: 1537, height: 2305 },
  { src: Image_00159, width: 2305, height: 1537 },
  { src: Image_00160, width: 2305, height: 1537 },
  { src: Image_00161, width: 2305, height: 1537 },
  { src: Image_00162, width: 2305, height: 1537 },
  { src: Image_00163, width: 1537, height: 2305 },
  { src: Image_00164, width: 1537, height: 2305 },
  { src: Image_00165, width: 1537, height: 2305 },
  { src: Image_00166, width: 2305, height: 1537 },
  { src: Video_00001, width: 848, height: 480 },
  { src: Image_00167, width: 2305, height: 1537 },
  { src: Image_00168, width: 1537, height: 2305 },
  { src: Image_00169, width: 2305, height: 1537 },
  { src: Image_00170, width: 1600, height: 722 },
  { src: Image_00171, width: 1128, height: 2016 },
  { src: Image_00172, width: 2049, height: 1775 },
];

const photos: Photo[] = weddingPhotos.map((photo) => {
  return {
    src: photo.src,
    width: photo.width,
    height: photo.height,
    // images: breakpoints.map((breakpoint) => {
    //   const height = Math.round((photo.height / photo.width) * breakpoint);
    //   return {
    //     src: unsplashLink(photo.id, breakpoint, height),
    //     width: breakpoint,
    //     height,
    //   };
    // }),
  };
});

export default photos;
