import React from "react";
import { DefaultReactComponentProps } from "../../types/DefaultReactComponentProps";

interface FancyTextProps extends DefaultReactComponentProps {}

const FancyText = (props: FancyTextProps) => {
  return <div className={"windSong"}>{props.children}</div>;
};

export default FancyText;
