import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import { Button, Form } from "react-bootstrap";
import CenteredItems from "../Utils/CenteredItems";
import { UserData } from "../../types/UserData";
import { Constants } from "../../constants/Constants";
import { InvitationProps } from "./index";
import UserDataContext from "../../context/UserDataContext";

interface InvitedRejectedProps extends InvitationProps {
  acceptRejectInvitation: (invitationId: string, acceptReject: boolean) => void;
}

const InvitedRejected = (props: InvitedRejectedProps) => {
  const { userData } = useContext(UserDataContext);
  if (!userData) {
    return null;
  }
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (userData.invitationId) {
      props.acceptRejectInvitation(userData.invitationId, true);
    }
  };
  return (
    <CenteredItems className={"text-black"}>
      <Col xs={false} md={3} />
      <Col xs={12} md={6}>
        <div className={"m-5"}>
          <h3>Wie schade!</h3>
          <p>Wir werden Dich sehr vermissen!</p>
          <p>Vielleicht entscheidest Du Dich ja noch um?</p>
        </div>
        <div className={"m-5"}>
          <h6>
            P.S.: Auch wenn Du wirklich nicht dabei sein kannst, mit Deiner
            Einladungs-ID kannst Du hier später Fotos sehen.
          </h6>
        </div>
      </Col>
    </CenteredItems>
  );
};

export default InvitedRejected;
