import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import {
  Alert,
  Button,
  Form,
  Table,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import CenteredItems from "../Utils/CenteredItems";
import { UserData } from "../../types/UserData";
import { Constants } from "../../constants/Constants";
import { InvitationProps } from "./index";
import Row from "react-bootstrap/Row";
import axios from "axios";

interface InvitationAcceptedProps extends InvitationProps {
  userData: UserData;
  showAlert: boolean | undefined;
  setShowAlert: (value: boolean) => void;
  alertMessage: string | undefined;
  updateAlertData: (show: boolean, message: string) => void;
  updateUserData: (userData: UserData) => void;
  acceptRejectInvitation: (invitationId: string, accept: boolean) => void;
}

const InvitationAccepted = (props: InvitationAcceptedProps) => {
  let [user, setUser] = useState(props.userData);
  const [success, setSuccess] = useState(false);
  if (props.userData.invitationId !== user.invitationId) {
    setUser(props.userData);
  }
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    updateUserData();
  };
  const handleReset = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (user && user.invitationId) {
      props.acceptRejectInvitation(user.invitationId, false);
    }
  };
  const updateUserData = () => {
    axios
      .post(`/hochzeit/api/invitation/${user.invitationId}/pref-update`, user)
      .then((result) => {
        if (result.data) {
          props.updateUserData(result.data);
          setSuccess(true);
        } else {
          props.updateAlertData(true, Constants.StaticStrings.errorFromBackend);
        }
      })
      .catch((error) => {
        props.updateAlertData(true, Constants.StaticStrings.errorTitle);
      });
  };

  const handleChange = (event: any) => {
    const eventName = event.target.name;
    if (eventName) {
      const eventValue = event.target.value;
      if (eventName.includes(Constants.InviteeInputKeysPrefix)) {
        if (eventName === Constants.InviteeInputKeys.emailId) {
          setUser({ ...user, email: eventValue });
        }
        if (eventName === Constants.InviteeInputKeys.vegetarian) {
          setUser({ ...user, vegetarian: event.target.checked === true });
        }
        if (eventName === Constants.InviteeInputKeys.polterabend) {
          setUser({ ...user, polterabend: event.target.checked === true });
        }
        if (eventName === Constants.InviteeInputKeys.allergies) {
          setUser({ ...user, allergies: eventValue });
        }
      } else if (eventName.includes(Constants.GuestInputKeysPrefix)) {
        const lastInteger: number = parseInt(eventName.slice(-1), 10);
        if (user.accompaniedBy) {
          if (eventName.includes(Constants.GuestInputKeys.emailId)) {
            for (const guest of user.accompaniedBy) {
              if (guest.id === lastInteger) {
                guest.email = eventValue;
              }
            }
            setUser({ ...user });
          }
          if (eventName.includes(Constants.GuestInputKeys.vegetarian)) {
            for (const guest of user.accompaniedBy) {
              if (guest.id === lastInteger) {
                guest.vegetarian = event.target.checked === true;
              }
            }
            setUser({ ...user });
          }
          if (eventName.includes(Constants.GuestInputKeys.polterabend)) {
            for (const guest of user.accompaniedBy) {
              if (guest.id === lastInteger) {
                guest.polterabend = event.target.checked === true;
              }
            }
            setUser({ ...user });
          }
          if (eventName.includes(Constants.GuestInputKeys.coming)) {
            for (const guest of user.accompaniedBy) {
              if (guest.id === lastInteger) {
                guest.coming = event.target.checked === true;
              }
            }
            setUser({ ...user });
          }
          if (eventName.includes(Constants.GuestInputKeys.allergies)) {
            for (const guest of user.accompaniedBy) {
              if (guest.id === lastInteger) {
                guest.allergies = eventValue;
              }
            }
            setUser({ ...user });
          }
        }
      }
    }
  };
  return (
    <CenteredItems className={"text-black"}>
      <Col xs={false} md={3} />
      <Col xs={12} md={6}>
        <div className={"m-3"}>
          <h1>Hallo {Constants.greetingName(user)}</h1>
          <h2>Juhuu, wir freuen uns auf Dich/Euch!</h2>
        </div>

        <Toast
          show={success}
          onClose={() => setSuccess(false)}
          autohide
          delay={3000}
          bg={"success"}
        >
          <Toast.Header>
            <h6>Daten erfolgreich gespeichert!</h6>
          </Toast.Header>
        </Toast>
        <Form
          onReset={handleReset}
          onSubmit={handleSubmit}
          onChange={handleChange}
        >
          <Form.Group className={"m-1"} controlId={"emailAddress"}>
            <Form.Control
              type={"email"}
              placeholder={"Email Id"}
              value={user.email}
              name={Constants.InviteeInputKeys.emailId}
            />
            <Form.Text>
              Gibt hier Deine E-Mail Adresse an, um Updates zu bekommen:
            </Form.Text>
          </Form.Group>
          {user && (
            <>
              <p className={"fs-6 mt-3"}>
                Damit es keine bösen Überraschungen gibt, lass uns wissen ob Du
                vegetarisches Essen bevorzugst oder Allergien hast. Und wen
                möchtest Du mitbringen?
              </p>
              <Table
                striped
                bordered
                responsive
                size="sm"
                className={"fs-6 mt-3"}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Polterabend</th>
                    <th>Ich komme</th>
                    <th>Vegetarisch</th>
                    <th>Allergien</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={`${Constants.InviteeInputKeys.row}`}>
                    <td>1</td>
                    <td>{Constants.generateFullName(user)}</td>
                    <td>
                      <Form.Check
                        type={"switch"}
                        checked={user.polterabend}
                        name={`${Constants.InviteeInputKeys.polterabend}`}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type={"switch"}
                        checked={true}
                        readOnly
                        name={`${Constants.InviteeInputKeys.coming}`}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type={"switch"}
                        checked={user.vegetarian}
                        name={`${Constants.InviteeInputKeys.vegetarian}`}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type={"text"}
                        placeholder={"Allergien"}
                        value={user.allergies}
                        name={`${Constants.InviteeInputKeys.allergies}`}
                      />
                    </td>
                  </tr>
                  {user.accompaniedBy &&
                    user.accompaniedBy.map((guest, index) => {
                      return (
                        <tr
                          key={`${Constants.InviteeInputKeys.row}- ${guest.id} - ${index}`}
                        >
                          <td>{index + 2}</td>
                          <td>
                            {" "}
                            {guest.child ? "👶" : ""}
                            {Constants.generateFullName(guest)}
                          </td>
                          <td>
                            <Form.Check
                              type={"switch"}
                              checked={guest.polterabend}
                              name={`${Constants.GuestInputKeys.polterabend}-${guest.id}`}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type={"switch"}
                              checked={guest.coming}
                              name={`${Constants.GuestInputKeys.coming}-${guest.id}`}
                            />
                          </td>
                          <td>
                            <Form.Check
                              type={"switch"}
                              checked={guest.vegetarian}
                              name={`${Constants.GuestInputKeys.vegetarian}-${guest.id}`}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type={"text"}
                              placeholder={"Allergien"}
                              value={guest.allergies}
                              name={`${Constants.GuestInputKeys.allergies}-${guest.id}`}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </>
          )}
          <Row className={"m-1"}>
            <Col xs={6}>
              <Form.Group controlId={"submit-button"}>
                <Button type="submit">Aktualisieren</Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Col>
    </CenteredItems>
  );
};

export default InvitationAccepted;
