import React from "react";
import { DefaultReactComponentProps } from "../../types/DefaultReactComponentProps";
import "./landingImage.css";
import Col from "react-bootstrap/Col";
import FancyText from "../Utils/FancyText";
import { AlternateCenteredItems } from "../Utils/CenteredItems";

interface LandingImageProps extends DefaultReactComponentProps {}

const LandingImage = (props: LandingImageProps) => {
  return (
    <div className={"landingBackground fullPage text-center"}>
      <AlternateCenteredItems>
        <Col xs={2} lg={4} />
        <Col lg={4} xs={8} className={"bg-white bg-opacity-50 p-3"}>
          <FancyText>
            <h1 className={"text-black"}> Wir haben geheiratet! </h1>
          </FancyText>
        </Col>
      </AlternateCenteredItems>
    </div>
  );
};

export default LandingImage;
