import React from 'react';
import {DefaultReactComponentProps} from "../../types/DefaultReactComponentProps";
import Row from "react-bootstrap/Row";
interface CenteredItemsProps extends DefaultReactComponentProps {
    className?: string | undefined
}
const CenteredItems = (props: CenteredItemsProps) => {
    return <Row className={(props.className ? props.className: '') + " h-auto" }>
        {props.children}
    </Row>;
};

export const AlternateCenteredItems = (props: CenteredItemsProps) => {
    return <Row className={(props.className ? props.className: '') + " h-100 align-items-center" }>
        {props.children}
    </Row>;
};

export default CenteredItems;
