import { UserData } from "../types/UserData";
import Address from "../types/Address";
import React from "react";

export enum Places {
  Kirche,
  Dgh,
  Eltern,
  Hut,
  A3Hotel,
  WesterwaldTreff,
}

export class Constants {
  private static localStoragePrefix: string = "maria_varij_";
  public static LocalStorage = {
    invitationId: Constants.localStoragePrefix + "invitationId",
    userDataInformation: Constants.localStoragePrefix + "userDataInformation",
  };
  public static QueryParams = {
    invitationId: "iid",
  };
  public static InvitationIds = [];

  public static StaticStrings = {
    errorTitle: "Unbekannte Fehler! Bitte melden Sie bie uns!",
    errorFromBackend:
      "Unbekannte Einladung Id. Bitte versuchen Sie erneut oder rufen Sie uns!",
    buttonSendFeedback: "Rückmeldung geben",
    buttonIWillComeAfterRejecting: "Ja, ich werde doch dabei sein",
    buttonIWillCome: "Ja, ich will",
    buttonIWillNotCome: "Nein, leider bin ich verhindert 😔",
    buttonIWillNotComeAfterAccepting: " Ich kann leider doch nicht kommen 😔",
  };

  public static greetingName = (userData: UserData) => {
    const mainInvitee = Constants.generateFullName(userData);
    let otherGuests: string[] = [];
    if (userData.accompaniedBy && userData.accompaniedBy.length > 0) {
      for (const guest of userData.accompaniedBy) {
        if (!guest.child) {
          const guestName = Constants.generateFullName(guest);
          otherGuests.push(guestName);
        }
      }
    }
    let greeting = mainInvitee;
    if (otherGuests.length > 0) {
      otherGuests.forEach((guestName, index) => {
        if (index < otherGuests.length - 1) {
          greeting = greeting + `, ${guestName}`;
        } else {
          greeting = greeting + ` & ${guestName}`;
        }
      });
    }
    return greeting;
  };
  public static generateFullName = (userData: UserData) => {
    return userData.firstName ? userData.firstName : "";
  };

  public static findUserData = () => {
    const inLocalStorage = localStorage.getItem(
      Constants.LocalStorage.userDataInformation
    );
    if (inLocalStorage === undefined || inLocalStorage === null) {
      return undefined;
    } else {
      return JSON.parse(inLocalStorage);
    }
  };

  public static findInvitationIdFromLocalStorage = () => {
    const inLocalStorage = localStorage.getItem(
      Constants.LocalStorage.invitationId
    );
    if (inLocalStorage === undefined || inLocalStorage === null) {
      return undefined;
    }
    return inLocalStorage;
  };

  public static InviteeInputKeysPrefix = "invitee-";
  public static InviteeInputKeys = {
    row: Constants.InviteeInputKeysPrefix + "row",
    emailId: Constants.InviteeInputKeysPrefix + "emailId",
    polterabend: Constants.InviteeInputKeysPrefix + "polterabend",
    coming: Constants.InviteeInputKeysPrefix + "coming",
    vegetarian: Constants.InviteeInputKeysPrefix + "vegetarian",
    allergies: Constants.InviteeInputKeysPrefix + "allergies",
  };
  public static GuestInputKeysPrefix = "guest-";
  public static GuestInputKeys = {
    row: Constants.GuestInputKeysPrefix + "row",
    emailId: Constants.GuestInputKeysPrefix + "emailId",
    polterabend: Constants.GuestInputKeysPrefix + "polterabend",
    coming: Constants.GuestInputKeysPrefix + "coming",
    vegetarian: Constants.GuestInputKeysPrefix + "vegetarian",
    allergies: Constants.GuestInputKeysPrefix + "allergies",
  };

  public static GetAddressForPlaces = (place: Places): Address => {
    switch (place) {
      case Places.Kirche:
        return {
          name: "St. Maria Magdalena Kirche Horhausen",
          address: "Kirchstraße 5 A, 56593 Horhausen (Westerwald)",
          info: "Kirchliche Trauung",
          position: [50.590421830749506, 7.5289417831507235],
        };
      case Places.Dgh:
        return {
          name: "Ortsgemeinde Pleckhausen",
          address: "Eiderbachstraße, 56593 Pleckhausen",
          info: "Feier",
          position: [50.59406143847133, 7.537913115691327],
        };
      case Places.Eltern:
        return {
          name: "Maria's Eltern",
          address: "Eiderbachstraße 3, 56593 Pleckhausen",
          info: "Polterabend",
          position: [50.59349101198219, 7.538086715345897],
        };
      case Places.Hut:
        return {
          name: "Freizeithütte",
          address: "Waldweg 1A, 56593 Pleckhausen",
          info: "Polterabend (Feier)",
          position: [50.59864828199686, 7.540549729183328],
        };
      case Places.A3Hotel:
        return {
          name: "A3 Hotel",
          address: "Westerwaldstr. 21, 56587 Oberhonnefeld-Gierend",
          info: "",
          position: [50.561854393977555, 7.527017786508089],
        };
      case Places.WesterwaldTreff:
        return {
          name: "Der Westerwald Treff",
          address: "In der Huth 1, 57641 Oberlahr",
          info: "",
          position: [50.61879334174331, 7.529346498287636],
        };
    }
  };

  public static AllPlacesForAnfahrt = [
    Places.Eltern,
    Places.Hut,
    Places.Kirche,
    Places.Dgh,
  ];
}
