import React, { useState } from "react";
import "./frontpage.css";
import { Carousel } from "react-bootstrap";
import LandingImage from "../LandingImage";
import InfoItem from "../InfoItem";

const FrontPage = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex: number, e: any) => {
    setIndex(selectedIndex);
  };
  return (
    <div className={"fullPage"}>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        variant={index > 0 ? "dark" : undefined}
      >
        <Carousel.Item className={"min-vh-100"}>
          <LandingImage />
        </Carousel.Item>
        <Carousel.Item className={"min-vh-100"}>
          <InfoItem />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default FrontPage;
