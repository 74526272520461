import React, { useEffect, useState } from "react";
import CenteredItems from "../Utils/CenteredItems";
import { Constants } from "../../constants/Constants";
import axios from "axios";
import { Form, Table } from "react-bootstrap";
import { WishList } from "../../types/WishList";
import Col from "react-bootstrap/Col";

const Index = () => {
  const [invitationId] = useState(Constants.findInvitationIdFromLocalStorage());
  const [userData] = useState(Constants.findUserData);
  const [wishList, setWishList] = useState([]);
  const isUserAllowed = () => {
    if (
      invitationId === undefined ||
      invitationId === null ||
      userData === undefined ||
      userData === null ||
      userData.invitationId === null ||
      userData.invitationId === undefined ||
      userData.accepted === false
    ) {
      return "";
    }
    return true;
  };
  const fetchWishList = () => {
    axios
      .get(`/hochzeit/api/wish-list/${invitationId}/`)
      .then((response) => {
        if (response.data) {
          setWishList(response.data);
        }
      })
      .catch((error) => {
        console.log("fetchWishList, Line 30 error: ", error);
      });
  };

  const markWishList = (wishId: number, take: boolean) => {
    axios
      .post(`/hochzeit/api/wish-list/${invitationId}/${wishId}/?take=${take}`)
      .then((response) => {
        if (response.data) {
          setWishList(response.data);
        }
      })
      .catch((error) => {
        console.log("fetchWishList, Line 30 error: ", error);
      });
  };
  useEffect(() => {
    fetchWishList();
  }, []);
  return (
    <div className={"floralBackground fullPagePositionRelative"}>
      <CenteredItems>
        <Col xs={false} md={3} />
        <Col xs={12} md={6} className={"m-2 p-2"}>
          {isUserAllowed() ?? (
            <h1>
              {" "}
              Um die Wish List an zu schauen, bitte akzeptieren Sie das
              Einladung
            </h1>
          )}
          {isUserAllowed() && (
            <div>
              <h2>Was wir uns wünschen</h2>
              <h5>
                Eigentlich haben wir ja alles was wir brauchen, aber wir wissen
                ja dass die Frage kommt, was man dem Brautpaar denn schenken
                kann.
              </h5>
              <h5>
                Also haben wir hier ein paar Ideen gesammelt: Wenn ihr uns
                tatsächlich von dieser Liste etwas schenken wollt, dann klickt
                es an, damit jeder sieht, dass die Geschenkidee schon vergeben
                ist.
              </h5>
              <h5>
                {" "}
                Und da wir aber trotzdem überrascht werden wollen, bleibt alles{" "}
                <strong>anonym</strong>, d.h. wir wissen nicht wer was
                angeklickt hat! Aber Vorsicht, das heißt auch, dass ihr euch
                merken müsst, was ihr ausgesucht habt ;)
              </h5>

              {wishList && wishList.length > 0 && (
                <Table striped responsive className={"fs-6"}>
                  <thead>
                    <tr>
                      <td> Name</td>
                      <td> Markieren</td>
                    </tr>
                  </thead>
                  <tbody>
                    {wishList.map((wish: WishList) => {
                      return (
                        <tr key={wish.id}>
                          <td> {wish.name} </td>
                          <td>
                            <Form.Check
                              checked={wish.taken}
                              onChange={(event) =>
                                markWishList(wish.id, event.target.checked)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          )}
        </Col>
      </CenteredItems>
    </div>
  );
};

export default Index;
