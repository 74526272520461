import React from "react";
import CenteredItems from "../Utils/CenteredItems";
import Col from "react-bootstrap/Col";
import { Card, Table } from "react-bootstrap";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import { Constants, Places } from "../../constants/Constants";
import Address from "../../types/Address";

export const renderAddress = (places: Places) => {
  let address: Address = Constants.GetAddressForPlaces(places);

  return (
    <>
      <h5>{address.name}</h5>
      <h6>{address.info}</h6>
      <p>{address.address}</p>
    </>
  );
};

const Index = () => {
  return (
    <div className={"floralBackground fullPagePositionRelative"}>
      <CenteredItems className={"m-2"}>
        <Col xs={false} md={3} />
        <Col xs={12} md={6}>
          <Card style={{overflow: "scroll"}}>
            <MapContainer
              center={Constants.GetAddressForPlaces(Places.Eltern).position}
              zoom={13}
              scrollWheelZoom
              style={{ height: "50vh", width: "100%" }}
            >
              <TileLayer
                detectRetina
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {Constants.AllPlacesForAnfahrt.map((places: Places) => {
                return (
                    <Marker
                        position={Constants.GetAddressForPlaces(places).position}
                        icon={new Icon({ iconUrl: markerIconPng })}
                    >
                      <Popup>{renderAddress(places)}</Popup>
                    </Marker>
                );
              })}

            </MapContainer>
            <Card.Body>
              <Card.Text>
                <Table responsive striped  className={'fs-6'}>
                  <tbody>
                    {Constants.AllPlacesForAnfahrt.map((places: Places) => {
                      const item = Constants.GetAddressForPlaces(places)
                      return (
                        <tr>
                          <td>{item.info}</td>
                          <td>{item.name}</td>
                          <td>{item.address}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </CenteredItems>
    </div>
  );
};

export default Index;
