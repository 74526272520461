import React                                    from "react";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
const Index = ({ latitude, longitude, address }) => {
    const position = [latitude, longitude];
  return (
    <MapContainer center={position} zoom={13} scrollWheelZoom style={{height: '50vh', width:'100%'}}>
      <TileLayer detectRetina
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} icon={new Icon({iconUrl: markerIconPng})}>
          <Popup>
              {address}
          </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Index;
