import React from "react";
import { Alert, Button, Form, Toast } from "react-bootstrap";
import CenteredItems from "../Utils/CenteredItems";
import Col from "react-bootstrap/Col";
import { InvitationProps } from "./index";

interface EnterInvitationProps extends InvitationProps {}

const EnterInvitation = (props: EnterInvitationProps) => {
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.setShowAlert(false);
    if (
      event &&
      event.target &&
      event.target.invitationIdField &&
      event.target.invitationIdField.value &&
      props.handleInvitationEntered
    ) {
      props.handleInvitationEntered(
        event.target.invitationIdField.value.toUpperCase()
      );
    }
  };
  return (
    <CenteredItems className={"text-black"}>
      <Col xs={false} md={3} />
      <Col xs={12} md={6}>
        <Form onSubmit={handleSubmit}>
          <Form.Group className={"m-5"} controlId={"alertForm"}>
            <Toast
              show={props.showAlert}
              bg={"danger"}
              onClose={() => props.setShowAlert(false)}
            >
              <Toast.Header>{props.alertMessage}</Toast.Header>
            </Toast>
          </Form.Group>
          <Form.Group className={"m-5"} controlId={"invitationIdField"}>
            <p>
              Hier könnt ihr uns mitteilen, ob ihr kommen wollt, oder ob ihr
              leider verhindert seid. Gebt hierzu einfach eure persönliche
              Einladungs-ID ein, die ihr auf der Rückseite des Anhängers an
              eurer Einladung findet.
            </p>
            <p>Oder ihr scannt einfach den QR Code mit eurem Smartphone.</p>
            <Form.Control
              required
              size={"lg"}
              type={"text"}
              placeholder={"Einladungs-ID"}
            />
          </Form.Group>
          <Form.Group className={"m-5"} controlId={"submit-button"}>
            <Button type="submit" variant={"success"}>
              Rückmeldung geben
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </CenteredItems>
  );
};

export default EnterInvitation;
