import React, { useEffect } from "react";
import { InvitationProps } from "./index";
import CenteredItems from "../Utils/CenteredItems";
import { Alert, Button, Form, Toast } from "react-bootstrap";
import Col from "react-bootstrap/Col";

interface InvitationFromUrlProps extends InvitationProps {
  invitationId: string;
  handleInvitationEntered: (invitationId: string) => void;
}

const InvitationFromUrl = (props: InvitationFromUrlProps) => {
  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.handleInvitationEntered(props.invitationId);
  };
  useEffect(() => handleOnSubmit(new Event("asas")));
  return (
    <CenteredItems>
      <Col xs={false} md={3} />
      <Col xs={12} md={6}>
        <Form onSubmit={handleOnSubmit}>
          <Form.Group className={"m-5"} controlId={"alertForm"}>
            <Toast
              show={props.showAlert}
              bg={"danger"}
              onClose={() => props.setShowAlert(false)}
            >
              <Toast.Header>{props.alertMessage}</Toast.Header>
            </Toast>
          </Form.Group>

          <Form.Group className={"m-5"} controlId={"text-data"}>
            <h1> Hallo! Willkommen!</h1>
            <h4>wir suchen Deine Einladung... </h4>
            <h5>Einladungs-ID: {props.invitationId}</h5>
          </Form.Group>
          {props.showAlert && (
            <Form.Group className={"m-5"} controlId={"submit-button"}>
              <Button type="submit" variant={"success"}>
                Erneut versuchen
              </Button>
            </Form.Group>
          )}
        </Form>
      </Col>
    </CenteredItems>
  );
};

export default InvitationFromUrl;
