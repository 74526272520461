import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { DefaultReactComponentProps } from "../../types/DefaultReactComponentProps";
import "./navigation.css";
import { NavLink } from "react-router-dom";
import { Constants } from "../../constants/Constants";
import UserDataContext from "../../context/UserDataContext";

interface NavigationProps extends DefaultReactComponentProps {}

const Index = (props: NavigationProps) => {
  const { userData } = useContext(UserDataContext);
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      fixed={"top"}
      expand={"lg"}
      className={"bg-light"}
      expanded={expanded}
    >
      <Container fluid>
        <Navbar.Brand className={"windSong text-black"}>
          <NavLink to={"/"}>Maria & Varij</NavLink>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls={"basic-navbar-nav"}
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id={"basic-navbar-nav"}>
          <Nav className={"me-auto"}>
            <NavLink
              className={"nav-link"}
              role={"button"}
              to={"/pictures/lkswuxupshi"}
              onClick={() =>
                setTimeout(() => {
                  setExpanded(false);
                }, 150)
              }
            >
              Fotos
            </NavLink>
            {userData && (
              <>
                <NavLink
                  className={"nav-link"}
                  role={"button"}
                  to={"/pictures/lkswuxupshi"}
                  onClick={() =>
                    setTimeout(() => {
                      setExpanded(false);
                    }, 150)
                  }
                >
                  Fotos
                </NavLink>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className={"justify-content-end"}>
          {userData && (
            <Navbar.Text>Hallo {Constants.greetingName(userData)}</Navbar.Text>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Index;
