import React, { useState } from "react";
import "./App.css";
import Navigation from "./components/Navigation";
import FrontPage from "./components/FrontPage";
import Invitation from "./components/Invitation";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import WishList from "./components/WishList";
import { UserData } from "./types/UserData";
import { Constants } from "./constants/Constants";
import UserDataContext from "./context/UserDataContext";
import Polterabend from "./components/Polterabend";
import Hotel from "./components/Hotel";
import Anfahrt from "./components/Anfahrt";
import Photos from "./components/Photos";

function App() {
  const [userData, setUserData] = useState(Constants.findUserData());
  const updateUserData = (user: UserData) => {
    localStorage.setItem(
      Constants.LocalStorage.userDataInformation,
      JSON.stringify(user)
    );
    setUserData(user);
  };
  return (
    <BrowserRouter>
      <UserDataContext.Provider
        value={{ userData: userData, updateUserData: updateUserData }}
      >
        <header className={"fs-5 row"}>
          <Navigation />
        </header>
        <main className={"fs-4"}>
          <Switch>
            <Route path={"/home"} component={FrontPage} />
            <Route path={"/invitation"} component={Invitation} />
            <Route path={"/wishlist"} component={WishList} />
            <Route path={"/info/polterabend"} component={Polterabend} />
            <Route path={"/info/hotel"} component={Hotel} />
            <Route path={"/info/anfahrt"} component={Anfahrt} />
            <Route path={"/pictures/lkswuxupshi"} component={Photos} />
            <Redirect to={"/home"} from={"/"} />
          </Switch>
        </main>
      </UserDataContext.Provider>
    </BrowserRouter>
  );
}

export default App;
